<template>
  <Html :lang="useLocale().getLocale().locale">

  <Head>
    <Link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
    <Link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
    <Link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
    <Link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
    <Link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
    <Link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
    <Link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
    <Link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
    <Link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
    <Link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
    <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <Link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
    <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <Link rel="manifest" href="/manifest.json" />
    <Meta name="msapplication-TileColor" content="#ffffff" />
    <Meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
    <Meta name="theme-color" content="#ffffff" />
    <Meta v-if="shopZone.allowedFeatures.includes('SEZNAM_WMT')" name="seznam-wmt" content="DyoeagEEbImvRxjpMeXl6aRtINi6aH4b" />
    <Meta v-if="robotsMeta" name="robots" :content="robotsMeta" />
  </Head>

  <Body :class="`sz-${shopZone.shopZoneId} bg-neutral-50 tracking-wider`">
    <div id="fb-root"></div>
    <targetComponent></targetComponent>
    <LazyOrganismsDialogsNoAlternativeUrl />
    <LazyOrganismsDialogsCartItemsRemoved />
    <LazyOrganismsDialogsAdultOnly />
    <LazyOrganismsDialogsCountryRedirectSuggestion />
    <Transition>
      <LazyOrganismsDialogsCookieConsent v-if="cookieConsentVisible" @consentGranted="cookieConsentGranted()" />
    </Transition>
    <LazyMoleculesGlobalBackToTop />
  </Body>

  </Html>
</template>

<script setup>

const appConfig = useAppConfig();
const pageData = usePage().value.page;
const shopZone = useShopZone().value;
const i18n = useI18n();
const route = useRoute();
const locale = useLocale().getLocale();

i18n.setLocale(shopZone.locale);

if (process.client && pageData?.adultOnly && !useCookie('adultConfirmed').value) {
  useDialog().open('adultOnly');
}

let moduleName = appConfig.moduleMapping[pageData?.module.id];

if (!moduleName) {
  moduleName = '404';
}
else if (moduleName === 'group' && pageData.entity.wareCount === 0) {
  moduleName = 'emptyGroup';
}

let targetComponent = defineAsyncComponent(
  () => import(`../components/pages/${moduleName}.vue`).catch(() => {

    if (process.client) {
      location.reload();
    }
  })
);

let robotsMeta = '';

if (shopZone.robotsIndexable) {
  if (pageData?.noIndex || appConfig.moduleIdsWithBlockedIndexing.includes(pageData?.module.id)) {
    robotsMeta = 'noindex, nofollow';
  }
  else if (shopZone.shopZoneId === 7 && moduleName === 'ware') {
    robotsMeta = 'noindex, follow';
  }
  else {
    robotsMeta = 'index, follow';
  }
}
else {
  robotsMeta = 'noindex, nofollow';
}

const path = route.path.replace(/\/$/, "");

useHead({
  title: (useShopCookie().read('cacheDisabled') ? '🐌 ' : '') + pageData?.title + shopZone.titleSuffix,
  meta: [
    { name: 'keywords', content: pageData?.keywords },
    { name: 'description', content: pageData?.description }
  ],
  link: [
    { rel: 'canonical', href: `https://${shopZone.domain}${path}` },
    { rel: 'alternate', href: `https://${shopZone.domain}${path}`, hreflang: shopZone.locale }
  ]
});

const cookieConsentVisible = computed(() => useCookie('cookieConsent').value == null || useState('cookieConsentVisible', () => false).value);

const cookieConsentGranted = () => {
  useState('cookieConsentVisible', () => false).value = false;
};

const handleHashChange = () => {
  const hash = window.location.hash.substring(1);

  const element = document.querySelector(`[data-anchor="${hash}"]`);

  if (element) {
    useUtils().scrollToElement(element);
  }

  if (hash === 'cookieConsent') {
    useState('cookieConsentVisible', () => false).value = true;
  }
}

useSeoMeta({
  ogImage: `${shopZone.staticResourcesRoot}${appConfig.defaultOgImage}`
});

onMounted(() => {

  window.addEventListener('hashchange', handleHashChange);

  watch(() => route.hash, () => {
    handleHashChange();
  });

  if (route.hash) {
    setTimeout(() => {
      handleHashChange();
    }, 750);
  }


});

//#region IP country based redirection suggestion 

onMounted(async () => {
  if (!useCookie('suggestionRedirectDeclined').value && !/Googlebot/.test(navigator.userAgent)) {

    const countryByIp = (await useApiFetch(`/api/countryByIP?languageId=${locale.languageId}&currencyId=${locale.currencyId}`))?.countryByIp.country?.id;

    if (!countryByIp) {
      return;
    }

    if (
      // CZ + EU but from outside CZ
      ((shopZone.shopZoneId === 9 || shopZone.shopZoneId === 10) && countryByIp !== appConfig.countries.CZ) ||

      // SK + EU but from outside SK
      ((shopZone.shopZoneId === 11 || shopZone.shopZoneId === 12) && countryByIp !== appConfig.countries.SK) ||

      // COM but from CZ or SK
      (shopZone.shopZoneId === 13 && (countryByIp === appConfig.countries.CZ || countryByIp === appConfig.countries.SK))
    ) {
      useDialog().open('countryRedirectSuggestion', {
        countryId: countryByIp
      });
    }
  }
});

//#endregion

if (process.server) {
  const event = useRequestEvent();

  useNuxtApp().hook('app:rendered', () => {

    useState('serverTiming', () => []).value.push({ started: new Date().getTime(), description: 'Page Load Finished' });

    const records = useState('serverTiming', () => []).value;

    //get record with smallest started value from the array
    const firstRecord = records.reduce((prev, current) => (prev.started < current.started) ? prev : current);

    const results = records.sort((a, b) => a.started - b.started).map((item, index) => `${index};desc="${(item.started - firstRecord.started).toString().padStart(4, '0')} ms: ${item.description}"${item.duration ? `;dur=${item.duration}` : ''}`);

    event.node.res.setHeader('Server-Timing', results.join(','));
  });
}

if (route.query.causeError) {
  throw new Error('Error caused by query parameter');
}

</script>
<style lang="postcss">
.margin-top-5 {
  @apply mt-1;
}

.margin-top-10 {
  @apply mt-2;
}

.margin-top-15 {
  @apply mt-4;
}

.margin-top-20 {
  @apply mt-5;
}

.margin-top-25 {
  @apply mt-6;
}

.margin-top-30 {
  @apply mt-8;
}

.margin-top-40 {
  @apply mt-10;
}

.margin-top-50 {
  @apply mt-12;
}

.margin-bottom-5 {
  @apply mb-1;
}

.margin-bottom-10 {
  @apply mb-2;
}

.margin-bottom-15 {
  @apply mb-4;
}

.margin-bottom-20 {
  @apply mb-5;
}

.margin-bottom-25 {
  @apply mb-6;
}

.margin-bottom-30 {
  @apply mb-8;
}

.margin-bottom-40 {
  @apply mb-10;
}

.margin-bottom-50 {
  @apply mb-12;
}

.content-2-col {
  @apply flex flex-wrap items-start gap-4 tablet:gap-6;

  &>* {
    @apply w-full tablet:flex-1;

    &>*:first-child {
      @apply tablet:mt-0;

      &>*:first-child {
        @apply tablet:mt-0;
      }
    }

    &>*:last-child {
      @apply tablet:mb-0;

      &>*:last-child {
        @apply tablet:mb-0;
      }
    }
  }
}

.content-col-1_3 {
  @apply tablet:flex-[1];
}

.content-col-2_3 {
  @apply tablet:flex-[3];
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.35s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.6s ease;
  max-height: 100px;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
}

.slidein-enter-active,
.slidein-leave-active {
  transition: all 0.5s ease-in-out;
}

.slidein-enter-from,
.slidein-leave-to {
  transform: translateY(-100%);
}

.sz-10 {
  .show-on-sz-9 {
    @apply !hidden;
  }
}
</style>